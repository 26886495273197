import * as THREE from 'three';

let fovPerspective = window.innerWidth < 700 ? 40 : 25;

document.addEventListener('resize', () => {
	if (window.innerWidth < 700) {
		fovPerspective = 40;
	} else {
		fovPerspective = 25;
	}
});

const camera = new THREE.PerspectiveCamera(fovPerspective, window.innerWidth / window.innerHeight, 1, 500);
camera.position.set(0, 0, 150);
camera.lookAt(0, 0, 0);

const scene = new THREE.Scene();
scene.background = new THREE.Color(0x101010);

const geometry = new THREE.SphereGeometry(15, 32, 36);
const material = new THREE.MeshNormalMaterial();
const sphere = new THREE.Mesh(geometry, material);
scene.add(sphere);

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setAnimationLoop(animation);

// const heroElem = document.querySelector('[data-view="hero-scene"]');
const heroElem = document.querySelector('#hero');
renderer.domElement.classList.add('hero-scene');
if (window.location.pathname === '/') heroElem.appendChild(renderer.domElement);

function animation(time) {
	sphere.rotation.x = time / 3000;
	sphere.rotation.y = time / 2000;
	renderer.render(scene, camera);
}

